import { ContextApi } from '@pancakeswap/localization'
import {
  MenuItemsType,
  MintNFTIcon, MintNFTIconFillIcon, NFTPoolIcon, NFTPoolIconFillIcon
} from '@pancakeswap/uikit'
import { DropdownMenuItems } from '@pancakeswap/uikit/src/components/DropdownMenu/types'
import { SUPPORT_ONLY_ONUS } from 'config/constants/supportChains'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
  [
    {
      label: t('Mint NFT'),
      icon: MintNFTIcon,
      fillIcon: MintNFTIconFillIcon,
      href: '/mintnft',
      showItemsOnMobile: false,
      items:
       [
        {
          label: t('Mint NFT'),
          href: '/mintnft',
          supportChainIds:SUPPORT_ONLY_ONUS
        }
      ].map((item) => addMenuItemSupported(item, chainId))
    },
    {
      label: t('NFT Pool'),
      icon: NFTPoolIcon,
      fillIcon: NFTPoolIconFillIcon,
      href: '/nftpool',
      showItemsOnMobile: false,
      items:
       [
        {
          label: t('NFT Pool'),
          href: '/nftpool',
          supportChainIds:SUPPORT_ONLY_ONUS
        }
      ].map((item) => addMenuItemSupported(item, chainId))
    },
  ].map((item) => addMenuItemSupported(item, chainId))

export default config
