import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  padding: 0;\n  border-radius:12px;\n  width: ",
        ";\n  background: transparent !important;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import Button from "./Button";
var IconButtonV2 = styled(Button).withConfig({
    componentId: "sc-d62038f9-0"
})(_templateObject(), function(param) {
    var scale = param.scale;
    return scale === "sm" ? "32px" : "48px";
});
export default IconButtonV2;
