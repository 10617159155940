import { createReducer } from '@reduxjs/toolkit'
import {
    getActiveTab, getPowerToStake, getPowerToUsStake, getSelectedTypeForStake, getSelectedTypeNftForUnstake
} from "./actions"


interface global {
    activeIndex:number,
    powerToStake: string,
    powerToUnstake:string,
    selectedTypeForStake:number,
    selectedTypeForUnstake:number
}
export const initialState: global = {
    activeIndex:0,
    powerToStake:"",
    powerToUnstake:"",
    selectedTypeForStake:0,
    selectedTypeForUnstake:0
}
export default createReducer(initialState, (builder) =>
    builder
        .addCase(getActiveTab, (state, action) => {
            state.activeIndex = action.payload.activeIndex
        })
        .addCase(getPowerToStake, (state, action) => {
            state.powerToStake = action.payload.powerToStake
        })
        .addCase(getPowerToUsStake, (state, action) => {
            state.powerToUnstake = action.payload.powerToUnstake
        })
        .addCase(getSelectedTypeForStake, (state, action) => {
            state.selectedTypeForStake = action.payload.selectedTypeForStake
        })
        .addCase(getSelectedTypeNftForUnstake, (state, action) => {
            state.selectedTypeForUnstake = action.payload.selectedTypeForUnstake
        })
)