import _define_property from "@swc/helpers/src/_define_property.mjs";
import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        " \n  font-size: ",
        ";\n  font-weight: 600;\n  line-height: 1.1;\n \n  ",
        " {\n    font-size: ",
        ";\n  }\n  background: ",
        ";\n  -webkit-background-clip: text;\n  -webkit-text-fill-color: transparent;\n  background-clip: text;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import Text from "../Text/Text";
import { tags, scales } from "./types";
var _obj;
var style = (_obj = {}, _define_property(_obj, scales.MD, {
    fontSize: "20px",
    fontSizeLg: "20px"
}), _define_property(_obj, scales.LG, {
    fontSize: "24px",
    fontSizeLg: "24px"
}), _define_property(_obj, scales.XL, {
    fontSize: "32px",
    fontSizeLg: "52px"
}), _define_property(_obj, scales.XXL, {
    fontSize: "100px",
    fontSizeLg: "100px"
}), _obj);
var Heading = styled(Text).attrs({
    bold: true
}).withConfig({
    componentId: "sc-47f34833-0"
})(_templateObject(), function(param) {
    var scale = param.scale;
    return style[scale || scales.MD].fontSize;
}, function(param) {
    var theme = param.theme;
    return theme.mediaQueries.lg;
}, function(param) {
    var scale = param.scale;
    return style[scale || scales.MD].fontSizeLg;
}, function(param) {
    var theme = param.theme;
    return theme.colors.colorgradientGold;
});
Heading.defaultProps = {
    as: tags.H2
};
export default Heading;
