import _define_property from "@swc/helpers/src/_define_property.mjs";
import { scales, variants } from "./types";
var _obj;
export var scaleVariants = (_obj = {}, _define_property(_obj, scales.MD, {
    height: "52px",
    padding: "0 24px"
}), _define_property(_obj, scales.SM, {
    height: "32px",
    padding: "0 16px"
}), _define_property(_obj, scales.XS, {
    height: "20px",
    fontSize: "12px",
    padding: "0 8px"
}), _obj);
var _obj1;
export var styleVariants = (_obj1 = {}, _define_property(_obj1, variants.PRIMARY, {
    background: "linear-gradient(95.63deg, #F0A534 3.54%, #FCC574 42.8%, #EF9515 101.43%)",
    color: "primary"
}), _define_property(_obj1, variants.SECONDARY, {
    background: "transparent",
    border: "2px solid",
    borderColor: "cardBorder",
    boxShadow: "none",
    color: "text",
    ":disabled": {
        background: "transparent"
    },
    ":hover": {
        background: "primaryBright",
        color: "white"
    }
}), _define_property(_obj1, variants.TERTIARY, {
    background: "tertiary",
    color: "primary"
}), _define_property(_obj1, variants.SUBTLE, {
    background: "linear-gradient(90deg, #BDBDBD 0%, #EFEFEF 51.13%, #BDBDBD 101.21%)",
    color: "primary"
}), _define_property(_obj1, variants.DANGER, {
    background: "linear-gradient(95.63deg, #FC4E4E 3.54%, #F87979 42.8%, #FC4E4E 101.43%)",
    color: "white"
}), _define_property(_obj1, variants.SUCCESS, {
    background: "success",
    color: "white"
}), _define_property(_obj1, variants.TEXT, {
    background: "transparent",
    color: "primary"
}), _define_property(_obj1, variants.LIGHT, {
    background: "transparent",
    color: "primary",
    boxShadow: "none",
    border: "1px solid",
    borderColor: "primary"
}), _obj1);
